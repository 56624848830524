<template>
  <el-container class="body">
    <el-header class="herder">
      <div class="headerLeft">
        <img src="../../assets/APP_logo.png" />
        <span>视云格广告营销平台</span>
      </div>
      <div class="headerCenter">
        <el-menu class="el-menu-demo" mode="horizontal" background-color="#003980" text-color="#FFFFFF"
          active-text-color="#FFFFFF" :default-active="$route.path" router>
          <el-menu-item class="menu-item" index="/advertising/adverList">
            <img src="../../assets/u156.png">
            <span>我的广告</span>
          </el-menu-item>
          <el-menu-item class="menu-item" index="/advertising/addAdver">
            <img src="../../assets/u157.png">
            <span>创建广告</span>
          </el-menu-item>
          <el-menu-item class="menu-item" index="/adverPlan/certification">
            <img src="../../assets/u154.png">
            <span>资质管理</span>
          </el-menu-item>
          <el-menu-item class="menu-item" index="/adverPlan/adverPlanAccount">
            <img src="../../assets/u26.png">
            <span>账户管理</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="headerRight">
<!--        <i class="el-icon-s-home iconSize"></i>
        <i class="el-icon-bell iconSize"></i> -->
        <img src="../../assets/u23.png" style="width:25px;height:25px">{{userInfo.userName}}  <div style="margin: 0 10px;">|</div>  <div @click="onLogout"><img style="width:25px;height:25px;vertical-align: middle;" src="../../assets/u21.png">退出</div>
      </div>
    </el-header>
    <el-main class="main">
      <div class="mainLeft">
        <adverCard v-if="sideMode=='card'"></adverCard>
				<adverMenu v-if="sideMode=='menu'"></adverMenu>
      </div>
 <!--     <div id="mainRight">
        <el-menu ref="menu" class="el-menu-demo" mode="horizontal" :default-active="
        pathList.indexOf($route.path) == -1 ? '/advertising/adverList' : $route.path" router>
          <el-menu-item class="menu-item" index="/advertising/adverList"
            v-show="$route.path.indexOf('advertising') != -1">
            我的计划
          </el-menu-item>
          <el-menu-item class="menu-item" index="/advertising/addAdver"
            v-show="$route.path.indexOf('advertising') != -1">
            创建广告
          </el-menu-item>
          <el-menu-item class="menu-item" index="/adverPlan/adverPlanAccount"
            v-show="$route.path.indexOf('adverPlan') != -1">
            我的消费
          </el-menu-item>
        </el-menu>
      </div> -->
      <router-view class="view"></router-view>
    </el-main>
  </el-container>
</template>

<script>
  import adverCard from '@/views/Advertising/adverCard.vue'
  import adverMenu from '@/views/Advertising/adverMenu.vue'
  export default {
    components: {
      adverCard,
			adverMenu
    },
    data() {
      return {
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
        pathList: ["/advertising/adverList", "/advertising/addAdver", "/adverPlan/adverPlanAccount"],
		sideMode: 'card'
      };
    },
	mounted() {
		this.onUpdate()
	},
	watch: {
		$route() {
			this.onUpdate()
		}
	},
	methods: {
		onUpdate() {
			const arr= ['certification','adverPlanAccount','adPassword']
			this.sideMode = arr.indexOf(this.$router.currentRoute.name)>-1 ? 'menu' : 'card'
		},
		onLogout() {
			localStorage.removeItem('token')
			this.$router.push({name:'login'})
		}
	}
  };
</script>
<style>
  ::-webkit-scrollbar {
    width: 0 !important;
  }
</style>
<style lang="scss" scoped>
  .body {
    height: 100vh;
    background-color: #F0F2F5;
  }

  .herder {
    background: #003980;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    align-items: center;

    .headerLeft {
      display: flex;
      align-items: center;
      width: 25vw;

      img {
        border-radius: 50%;
        margin-right: 10px;
      }

      span {
        font-size: 18px;
      }
    }

    .headerCenter {
      display: flex;
      align-items: center;
      width: 60vw;

      // .is-active {
      //   background: #1890FF !important;
      // }

      .menu-item {
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8px;
		img {
			width:25px;
			height: 25px;
		}
        span {
          line-height: 25px;
        }
      }
    }

    .headerRight {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 30vw;

      .iconSize {
        font-size: 18px;
        margin: 0 20px;
        cursor: pointer;
      }
    }
  }

  .main {
    .mainLeft {
      float: left;
      width: 300px;
    }

    #mainRight {
      overflow: auto;
      padding-left: 20px;

      .el-menu-demo {
        background: #F0F2F5;
        border: none;

        .menu-item {
          border: none;
          font-size: 20px;
          width: 150px;
          color: gray;
          background: white;
        }

        .is-active {
          background: #1890FF;
          color: #FFFFFF !important;
        }
      }
    }

    .view {
      float: left;
      width: calc(100% - 300px);
      padding-left: 20px;
    }
  }
</style>
