<template>
  <div class="main">
    <div class="cardOne" v-loading="loading">
      <p class="cardTitle">
        <span>账户余额</span>
        <i class="el-icon-warning-outline"></i>
      </p>
      <p class="cardNum">&yen; {{advData.balance}}</p>
      <p class="cardRight">{{consumeDay}}</p>
      <hr />
      <p>充值请联系客服</p>
    </div>
    <div class="cardTwo" v-loading="loading">
      <p class="cardTitle">
        <span>今日花费</span>
        <i class="el-icon-warning-outline"></i>
      </p>
      <p class="cardNum">&yen; {{advData.todaySpend}}</p>
<!--      <p class="cardRight">
        <span>全部展示量 12%</span>
        <i class="el-icon-caret-top" style="color: red;"></i>
      </p> -->
      <hr />
      <p>{{consumeDay}}</p>
    </div>
    <div class="cardThree" v-loading="loading">
      <p class="cardTitle">
        <span>每日预算</span>
        <i class="el-icon-warning-outline"></i>
      </p>
      <p class="cardNum">&yen; {{advData.everydayBudget}}</p>
      <p class="cardRight">
        <el-link class="link" :underline="false" icon="el-icon-edit" @click="bidClick()"><span class="span">预算调整</span>
        </el-link>
      </p>
      <hr />
      <p>点击调整预算，来调整每日预算限制</p>
    </div>
  </div>
</template>

<script>
  import {
    everyDayBudget,
    advUserInfo
  } from '@/api/agentApi.js'
  export default {
    data() {
      return {
        loading: false, //加载框
        advData: {}, //广告主信息
        consumeDay: '' //预计消费日期
      }
    },
    created() {
      this.advUserInfo()
    },
    methods: {
      bidClick() { //点击预算调整
        this.$prompt('请输入今日总预算:', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^([1-9][0-9]*)$/,
          inputErrorMessage: '今日总预算必须是大于0的整数金额'
        }).then(({
          value
        }) => {
          let data = {
            budget: this.$util.getMoney(value)
          }
          everyDayBudget(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                type: 'success',
                message: '每日预算修改成功！'
              });
              this.advUserInfo()
            } else {
              this.$message({
                type: 'error',
                message: res.status.msg
              });
            }
          }).catch(err => console.log(err))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消修改！'
          });
        });
      },
      advUserInfo() { //获取广告主信息
        this.loading = true
        advUserInfo({}).then(res => {
          this.advData = res.data //获取广告主信息
          this.advData.balance = this.$util.setMoney(this.advData.balance)
          this.advData.todaySpend = this.$util.setMoney(this.advData.todaySpend)
          this.advData.everydayBudget = this.$util.setMoney(this.advData.everydayBudget)
          if(this.advData.todaySpend == 0){
            this.consumeDay = "今日暂无消费"
          }else{
            this.consumeDay = this.advData.everydayBudget !=0 ? "预计消费"+(this.advData.balance / this.advData.todaySpend)
              .toFixed(0)+"天" : "请先设置每日预算"
          }
          this.loading = false
        }).catch(err => console.log(err))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main {
    display: flex;
    flex-direction: column;

    .cardOne {
      background: linear-gradient(#19BE6B, #19BEB3);
    }

    .cardTwo {
      background: linear-gradient(#FF7000, #FFB300);
    }

    .cardThree {
      background: linear-gradient(#EE4115, #FF9100);
    }

    .cardOne,
    .cardTwo,
    .cardThree {
      text-align: left;
      padding: 0 10%;
      color: #FFFFFF;
      margin-bottom: 20px;
      font-size: 14px;
      font-family: 萍方-简;

      .cardTitle {
        display: flex;
        justify-content: space-between;
      }

      .cardNum {
        font-size: 30px;
      }

      hr {
        border: 0;
        border-top: 1px solid #E8E8E8;
      }

      .cardRight {
        text-align: right;

        .link {
          color: white;
        }

        .span {
          color: white;
          border-bottom: 1px solid white;
        }
      }
    }
  }
</style>
